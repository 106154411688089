.login-background {
  height: 100%;
  background-color: #f4f4f4;
  padding: 3rem 1.2rem;
}

.login-background > .ant-row-flex {
  height: 100%;
}

.login-background > .ant-row-flex > .ant-col {
  width: 80%;
}

.fit-logo {
  min-width: 100px;
  min-height: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  padding-bottom: 2em;
}

.fit-logo > svg > g {
  fill: rgb(255, 255, 255) ;
}

.simple {
  font-family: Rockwell;
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  text-align: center;
  width: 100%;
  display: block;
  color: #fff;
}

@media (max-width: 767px) {
  .fit-logo {
    width: 30%;
    padding-bottom: 1.5em;
    padding-top: 1em;
  }

  .simple {
    font-size: 1.2rem;
    letter-spacing: 0.15em;
  }

  .login-background {
    height: unset;
  }
}

@primary-color: #1d262e;@highlight-color: #ed6a5a;@normal-color: #f4f4f4;@error-color: #e3342f;@layout-body-background: #f4f4f4;@input-placeholder-color: hsv(0, 0, 70%);@menu-item-active-bg: #ffffffc4;