@import url('https://fonts.googleapis.com/css?family=Open+Sans|Poppins&display=swap');

html {
  min-height: 100vh;
  min-height: 100dvh;
}

*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

@media (min-width: 640px) {
  .ant-layout-content-form-wrapper{
    margin-top: 3rem;
  }
}

.visible{
  visibility: visible !important;
}

.collapse{
  visibility: collapse !important;
}

.static{
  position: static !important;
}

.fixed{
  position: fixed !important;
}

.absolute{
  position: absolute !important;
}

.relative{
  position: relative !important;
}

.sticky{
  position: -webkit-sticky !important;
  position: sticky !important;
}

.inset-0{
  inset: 0px !important;
}

.bottom-2\/5{
  bottom: 40% !important;
}

.left-0{
  left: 0px !important;
}

.right-0{
  right: 0px !important;
}

.top-0{
  top: 0px !important;
}

.m-0{
  margin: 0px !important;
}

.mx-1{
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mb-0{
  margin-bottom: 0px !important;
}

.mb-2{
  margin-bottom: 0.5rem !important;
}

.ml-2{
  margin-left: 0.5rem !important;
}

.mt-2{
  margin-top: 0.5rem !important;
}

.mt-4{
  margin-top: 1rem !important;
}

.block{
  display: block !important;
}

.inline{
  display: inline !important;
}

.flex{
  display: flex !important;
}

.table{
  display: table !important;
}

.grid{
  display: grid !important;
}

.hidden{
  display: none !important;
}

.h-full{
  height: 100% !important;
}

.w-full{
  width: 100% !important;
}

.flex-grow{
  flex-grow: 1 !important;
}

.border-collapse{
  border-collapse: collapse !important;
}

.transform{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.resize{
  resize: both !important;
}

.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.items-center{
  align-items: center !important;
}

.justify-start{
  justify-content: flex-start !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-between{
  justify-content: space-between !important;
}

.gap-2{
  gap: 0.5rem !important;
}

.overflow-auto{
  overflow: auto !important;
}

.border{
  border-width: 1px !important;
}

.border-primary-500{
  --tw-border-opacity: 1 !important;
  border-color: rgb(29 38 46 / var(--tw-border-opacity)) !important;
}

.bg-offwhite{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity)) !important;
}

.bg-primary-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 38 46 / var(--tw-bg-opacity)) !important;
}

.object-cover{
  object-fit: cover !important;
}

.p-2{
  padding: 0.5rem !important;
}

.px-1{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pb-2{
  padding-bottom: 0.5rem !important;
}

.pb-4{
  padding-bottom: 1rem !important;
}

.pt-2{
  padding-top: 0.5rem !important;
}

.text-center{
  text-align: center !important;
}

.align-top{
  vertical-align: top !important;
}

.text-lg{
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-sm{
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-xs{
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.font-bold{
  font-weight: 700 !important;
}

.font-semibold{
  font-weight: 600 !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.italic{
  font-style: italic !important;
}

.text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}

.text-gray-700{
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}

.text-offwhite{
  --tw-text-opacity: 1 !important;
  color: rgb(244 244 244 / var(--tw-text-opacity)) !important;
}

.text-primary-500{
  --tw-text-opacity: 1 !important;
  color: rgb(29 38 46 / var(--tw-text-opacity)) !important;
}

.underline{
  text-decoration-line: underline !important;
}

.antialiased{
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.outline{
  outline-style: solid !important;
}

.grayscale{
  --tw-grayscale: grayscale(100%) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter{
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition{
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

body,
html,
#root{
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(29 38 46 / var(--tw-text-opacity));
  height: 100%;
  font-family: 'Open Sans', 'Verdana', sans-serif;
}

.bg {
  background: url('./assets/bg_sider.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  -ms-content-zooming: none;
  height: 100%;
}

.line-icon-report {
  height: 5px;
  width: 100%;
  margin: 3px 0px;
}

.main-content {
  display: block;
  width: 100%;
  transition-property: all;
  transition-duration: 0.5s;
  min-height: 510px;
}

.main-content-sider-collapsed {
  display: none;
}

/* antd Header */

.ant-layout-header {
  font-size: 1.3em;
  height: 3.5em;
  line-height: 3.5em;
  padding-top: 0.2em;
  padding-right: 12px;
  background: rgba(240, 242, 245, 0);
  font-family: 'Poppins';
  font-weight: 600;
}

@media (min-width: 768px) {
  .ant-layout-header {
    font-size: 1.5em;
    height: 48px;
    line-height: 48px;
    padding-top: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* END: antd Header */

/* antd Tabs*/

.ant-tabs .ant-tabs-tab {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* END: antd Tabs */

/* Custom Tags */

.ant-tag {
  font-size: 1em;
  padding: 0.3em;
  margin-bottom: 0.3em;
}

.ant-tag-magenta {
  color: #ac0810;
  border-color: #b62e27;
  background: #fff4f3;
}

.ant-tag-red {
  color: #c60c16;
  background: #fff4f3;
}

.ant-tag-yellow {
  color: #bd9b03;
  background: #fffaec;
  border-color: #ffd88f;
}

.ant-tag-green {
  color: #389708;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-checkable {
  cursor: pointer;
}

.ant-tag-checkable-checked.ant-tag-checkable-red {
  background: #c60c16;
}

.ant-tag-checkable-checked.ant-tag-checkable-green {
  background: #389708;
}

.ant-tag-checkable-checked.ant-tag-checkable-yellow {
  background: #bd9b03;
}

/* END: Custom Tags */

/* form items */

.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  padding: 5px 11px;
  font-size: 14px;
}

.ant-btn-lg {
  height: 37px;
  padding: 5px 11px;
  font-size: 14px;
  border-radius: 2px;
}

.ant-radio-button-wrapper {
  border-radius: 4px !important;
  margin: 2px !important;
  border-left-width: 1px !important;
}

.ant-radio-button-wrapper:before {
  width: 0px !important;
}

.ant-form .ant-form-item {
  margin-bottom: 0.5rem;
}

.ant-form .ant-form-item .ant-form-item-label {
  padding-bottom: 0;
}

.ant-input-number {
  width: 100%;
}

.ant-form-item .ant-btn {
  float: right;
}

@media (min-width: 640px) {
  /* setting original styles again */

  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }

  .ant-btn-lg {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
  }

  /* end: setting original styles again */

  .ant-form-item .ant-btn {
    float: unset;
  }

  .ant-form .ant-form-item {
    margin-bottom: 1.5rem;
  }

  /* .ant-form .ant-form-item .ant-form-item-label {
    padding-bottom: 0.5rem;
  } */
}

/* END: form items */

/* antd Sider*/

.whitewash {
  background-color: rgba(255, 255, 255, 0.5) !important;
  background-blend-mode: color !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}

.ant-tabs-nav-operations > button {
  display: none !important;
}

.ant-tabs-nav-operations::after {
  content: '...';
  padding-left: 0.125rem;
  padding-top: 0.5rem;
}

.ant-tabs-content-holder {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

/* basic styling for sider: card effect, bold text, another font*/

.ant-layout-sider {
  font-family: 'Poppins';
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 0px rgba(0, 0, 0, 0.3), 3px 0px 6px 0px rgba(0, 0, 0, 0.3),
    0px 0px 0px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  min-height: 510px;
}

/* apply basic styles on the trigger (only shown on mobile) */

.ant-layout-sider-zero-width-trigger{
  --tw-bg-opacity: 1;
  background-color: rgb(29 38 46 / var(--tw-bg-opacity));
  top: 16px !important;
  z-index: 2;
  display: block;
  height: unset;
}

/* show the trigger inside the menu on mobile*/

.trigger-adjust {
  right: 0px !important;
  transform: rotate(180deg) !important;
}

/* smoother animation */

.ant-menu-inline-collapsed {
  width: unset !important;
}

@media (min-width: 768px) {
  .ant-layout-sider {
    font-weight: 600;
  }

  /* hide trigger on larger screens */

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
}

/* END: antd Sider*/

/* antd picker */

.ant-picker {
  width: 100%;
}

.disabled-ant-picker-super-btn .ant-picker-header-super-next-btn,
.disabled-ant-picker-super-btn .ant-picker-header-super-prev-btn {
  display: none;
}

.ant-picker-header-next-btn,
.ant-picker-header-prev-btn {
  width: 40px;
}

.ant-picker-cell {
  height: 32px;
  line-height: 32px;
}

/* END: antd picker */

/* antd select with clear */

.always-show-clear .ant-select-clear {
  opacity: 1;
}

/* END antd select with clear */

#root .ant-checkbox-group .custom-daily-checkbox{
  margin: 0px;
}

.custom-daily-checkbox{
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  border-color: #d9d9d9;
}

.custom-daily-checkbox.ant-checkbox-wrapper-checked{
  --tw-bg-opacity: 1;
  background-color: rgb(29 38 46 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.custom-daily-checkbox.ant-checkbox-wrapper-checked.custom-daily-checkbox-other{
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.custom-daily-checkbox > span:not(.ant-checkbox){
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.custom-daily-checkbox span > .ant-checkbox-inner {
  display: none;
}

.custom-daily-checkbox > span.ant-checkbox-checked::after {
  display: none;
}

.last\:mb-0:last-child{
  margin-bottom: 0px !important;
}

@media (min-width: 640px){
  .sm\:pt-0{
    padding-top: 0px !important;
  }
}

@media (min-width: 768px){
  .md\:bottom-0{
    bottom: 0px !important;
  }

  .md\:right-1\/3{
    right: 33.333333% !important;
  }

  .md\:px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
}

@media (min-width: 1024px){
  .lg\:mr-2{
    margin-right: 0.5rem !important;
  }

  .lg\:mt-0{
    margin-top: 0px !important;
  }

  .lg\:flex{
    display: flex !important;
  }

  .lg\:w-1\/2{
    width: 50% !important;
  }
}
